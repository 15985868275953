import { memo } from 'react';
import classnames from 'classnames';
import InstagramIcon from '@/components/svgs/instagram-color.svg';
import Arrow from '@/components/svgs/circle-arrow.svg';
import { useRouter } from 'next/router';

import styles from './Footer.module.scss';

function Footer() {
  const router = useRouter();
  return (
    <footer className={classnames(styles.Footer)}>
      {router.route === '/maratona-benessere-donna' ? (
        <div className={styles.controindicazioni}>
          <h3>Controindicazioni</h3>
          <ul>
            <li>Gravidanza in qualsiasi momento </li>
            <li>
              Sono trascorsi meno di 3 mesi dopo l’intervento chirurgico o il parto Cesareo e meno di 2 mesi dopo il
              parto naturale
            </li>
            <li>Dopo il parto Cesareo vi potete allenare SOLO dopo il controllo della cicatrice</li>
            <li>Processi infiammatori del sistema genito-urinario</li>
            <li>Gli ultimi gradi del prolasso degli organi interni</li>
            <li>Fibromi sanguinanti e neoplasie</li>
            <li>Recenti lesioni pelviche</li>
            <li>Con la presenza del ernie vi potete allenare SOLO con il permesso di medico di famiglia</li>
            <li>I dolori acuti o infiammazione dell’addome e del sistema genito-urinario</li>
            <li>Tumori maligni </li>
            <li>Prolasso uterino di 3-4 grado </li>
            <li>Fibromi sanguinanti</li>
            <li>Tumori benigni in fase di crescita</li>
            <li>Eventuali altre controindicazioni mediche</li>
          </ul>
        </div>
      ) : null}
      {router.route === '/maratona-latob' ? (
        <div className={styles.controindicazioni}>
          <h3>Controindicazioni</h3>
          <ul>
            <li>Gravidanza in qualsiasi momento </li>
            <li>Coxartrosi di grado 2-3</li>
            <li>Sono passati meno di 2 mesi (parto naturale) e 3 mesi (parto Cesareo) di 8 settimane</li>
            <li>Fibromi sanguinanti</li>
            <li>Ernie inguinali, ferite o ernie della linea alba dell’addome</li>
            <li>Tumori benigni in fase di crescita</li>
            <li>Ernia o protrusione della colonna vertebrale in fase acut</li>
            <li>Dolori acuti o infiammazione dell’addome e del sistema genito-urinario</li>
            <li>3-4 grado di prolasso degli organi interni</li>
            <li>Emorroidi sanguinanti o i noduli prolassi</li>
            <li>Diastasi superiore a 4cm</li>
            <li>Con la presenza di ernie vi potete allenare SOLO con il permesso del medico di famiglia</li>
          </ul>
        </div>
      ) : null}
      <div className={styles.content}>
        <InstagramIcon className={styles.insta} />
        <h3>Iscrizioni tramite messaggi privati di Instagram</h3>
        <a className={styles.btn} href="https://www.instagram.com/nadinebenesseredonna" target="blank">
          <span>Iscriviti</span> <Arrow />
        </a>
      </div>
    </footer>
  );
}

export default memo(Footer);
